<template>
  <v-container class="my-5">
    <v-card>
      <v-card-title>
        <span class="headline primary--text">{{ type.name }}</span>
      </v-card-title>

      <v-card-text>
        <v-layout row wrap class="pa-3" v-for="f in item.fields" :key="f.key">
          <v-flex xs12 class="ml-3" v-if="f.type === 'date'">
            <v-col cols="12" sm="6" md="4">
              <v-menu
                  v-model="modal"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field
                      v-model="f.value"
                      :label="f.label"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="f.value" @input="modal = false"/>
              </v-menu>
            </v-col>
          </v-flex>
          <v-flex v-else-if="f.type === 'image'">
            <div v-if="f.value">
              <div>Изображение</div>
              <v-img :src="f.value"
                     height="100px"
                     width="100px"
                     @click="$refs[f.key][0].click()"
                     dark>
              </v-img>
            </div>
            <div v-show="!f.value">
              <input type="file"
                     :id="f.key"
                     :ref="f.key"
                     accept="image/*"
                     v-on:change="handleFileUpload(f)"/>
            </div>
          </v-flex>
          <v-flex xs12 class="ml-3" v-else>
            <v-text-field v-model="f.value" :label="f.label"/>
          </v-flex>
        </v-layout>
      </v-card-text>

      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary"
               text
               @click="close">Отменить
        </v-btn>
        <v-btn color="primary"
               text
               @click="save">Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>

import {uuid} from "uuidv4";

export default {
  data: () => ({
    modal: false,
    item: {
      document_id: uuid.Nil,
      driver_id: "",
      document_type_id: "",
      fields: []
    },
    type: {}
  }),

  created() {
    let id = this.$route.params.id;
    let driver_id = this.$route.params.driver_id;
    this.$store.dispatch("drivers/fetchDocument", driver_id);
    this.$store.dispatch("documentTypes/fetch");

    if (id !== uuid.Nil) {
      if (this.current) {
        this.item = Object.assign({}, this.current);
      }
    } else {
      if (this.currentType) {
        this.setValues(this.currentType);
      }
    }
  },

  computed: {
    currentType() {
      return this.$store.getters["documentTypes/item"](this.$route.params.document_type_id);
    },
    current() {
      if (this.$route.params.id !== uuid.Nil) {
        return this.$store.getters["drivers/document"](this.$route.params.id);
      } else {
        return ""
      }
    }
  },

  watch: {
    currentType(newValue) {
      if (newValue) {
        this.setValues(newValue);
      }
    },
    current(newValue) {
      if (newValue && this.$route.params.id !== uuid.Nil) {
        this.item = Object.assign({}, newValue);
      }
    }
  },

  methods: {
    setValues(val) {
      this.type = Object.assign({}, val);

      if (this.$route.params.id === uuid.Nil) {
        this.item.fields = [];
        this.type.fields.forEach(f => {
          let v = Object.assign({}, f);

          if (v.type === "date") {
            v.value = new Date().toISOString().substr(0, 10);
          } else {
            v.value = "";
          }

          this.item.fields.push(v);
        })
      }
    },

    handleFileUpload(f) {
      let file = this.$refs[f.key][0].files[0];
      this.$store.dispatch("drivers/uploadDocument",
          {
            id: this.$route.params.id,
            driver_id: this.$route.params.driver_id,
            file: file,
            key: f.key,
          }).then(resp => {
        f.value = resp;
      });
    },

    save() {
      let id = this.$route.params.id;
      let driver_id = this.$route.params.driver_id;
      let document_type_id = this.$route.params.document_type_id;

      this.item.document_id = id;
      this.item.driver_id = driver_id;
      this.item.document_type_id = document_type_id;

      this.$store.dispatch("drivers/saveDocument", Object.assign({}, this.item));
      this.close();
    },

    close() {
      this.item.document_id = uuid.Nil;
      this.item.driver_id = "";
      this.item.document_type_id = "";
      this.item.fields = [];

      this.$router.push({name: 'driversDocument', params: {id: this.$route.params.driver_id}})
    }
  }
};
</script>